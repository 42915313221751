import {IStepperItem} from '../../interfaces/IStepperItem'
import React from 'react'

export const StepperItem = (item: IStepperItem) => {
  const {step, title, isCurrent, totalSteps} = item
  return (
    <div
      className={`stepper-item d-flex flex-column align-items-center ${
        isCurrent ? 'current' : ''
      }`}
      data-kt-stepper-element="nav"
    >
      <div className="d-flex align-items-center justify-content-center">
        {/* Stepper Icon */}
        <div className="stepper-icon position-relative">
          <i className="stepper-check fas fa-check"></i>
          <span className="stepper-number">{step}</span>
        </div>
        {/* Separator */}
        {step !== totalSteps && <div className="separator"></div>}
      </div>
      {/* Label */}
      <div className="stepper-label pt-2">
        <h3 className="custom-stepper-title stepper-title">{title}</h3>
      </div>
    </div>
  )
}