import { FC } from 'react'
import { useParams } from 'react-router-dom'
import EditButton from '../../shared/components/EditButton'
import { EditFinancierFinancials } from '../modals'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../data/redux/Store'
import { createFinancierSelectors, useGetFinanciersQuery } from '../FinanciersApiSlice'
import LineSpan from '../../shared/components/LineSpan'
import { formatNumber } from '../../../../utils/numberHelpers'

type FinancierFinancialsProps = {
  handleClose: () => void
  showModal: boolean
  currentPage: number
  pageSize: number
}

const FinancierFinancials: FC<FinancierFinancialsProps> = ({
  handleClose,
  showModal,
  currentPage,
  pageSize,
}) => {
  const { id } = useParams<{ id: string }>() as { id: string }
  useGetFinanciersQuery({ page: currentPage, pageSize: pageSize })
  const { selectById: selectFinancierById } = createFinancierSelectors({
    page: currentPage,
    pageSize,
  })
  const financierDetails = useSelector((state: RootState) => selectFinancierById(state, id))
  const financingTypes = [
    (financierDetails?.debtMaximumTicketSize ?? 0) > 0 ? 'Debt' : '',
    (financierDetails?.grantMaximumTicketSize ?? 0) > 0 ? 'Grant' : '',
    (financierDetails?.equityMaximumTicketSize ?? 0) > 0 ? 'Equity' : '',
  ].filter((type) => type)
  return (
    <div className='position-relative'>
      <div className='card card-flush mb-4'>
        <div className='card-body py-5 px-6'>
          <div className='row pb-2'>
            <div className='col-md-6'>
              <h3 className='fw-bolder text-dark fs-8 m-0'>Financing Types</h3>
              <span className='fs-7 text-justify'>
                {financingTypes?.length
                  ? financingTypes
                    .filter((type) => type)
                    .map((type, index) => (
                      <span key={index}>
                        {type}
                        {index < financingTypes.length - 1 && ', '}
                      </span>
                    ))
                  : <LineSpan />}
              </span>
            </div>

            <div className='col-md-6'>
              <h3 className='fw-bolder text-dark fs-8 m-0'>Investment Horizon</h3>
              <p className='fs-7 text-justify'>
              {financierDetails?.investmentHorizon?.length ? financierDetails?.investmentHorizon.join(', ') : <LineSpan />}
              </p>
            </div>
          </div>
          <div className='row pb-2'>
            <div className='col-md-6'>
              <h3 className='fw-bolder text-dark fs-8 m-0'>Maximum Ticket Size</h3>
              <p className='fs-7 text-justify'>
                {financierDetails?.currency} {formatNumber(financierDetails?.maximumTicketSize || '')}
              </p>
            </div>
            <div className='col-md-6'>
              <h3 className='fw-bolder text-dark fs-8 m-0'>Maximum Ticket Size</h3>
              <p className='fs-7 text-justify'>
                {financierDetails?.currency} {formatNumber(financierDetails?.minimumTicketSize || '')}
              </p>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-6'>
              <h3 className='fw-bolder text-dark fs-8 m-0'>Investor Type</h3>
              <span className='fs-7 text-justify'>
                {financierDetails?.financierType?.length ? financierDetails?.financierType.join(', ') : <LineSpan />}
              </span>
            </div>
          </div>
        </div>
      </div>
      <EditButton handleClose={handleClose} />
      <EditFinancierFinancials
        showModal={showModal}
        handleClose={handleClose}
        currentPage={currentPage}
        pageSize={pageSize}
      />
    </div>
  )
}

export default FinancierFinancials
