import { FC } from 'react'
import { useParams } from 'react-router-dom'
import EditButton from '../../shared/components/EditButton'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../data/redux/Store'
import { UpdateFinancierGeneralInformation } from '../modals'
import {
  createFinancierSelectors,
  useGetFinancierCountriesQuery,
  useGetFinanciersQuery,
  createFinancierCountriesSelectors,
  useGetFinancierSectorsQuery,
} from '../FinanciersApiSlice'
import LineSpan from '../../shared/components/LineSpan'
import { selectSectorById } from '../../companies/CompaniesApiSlice'
import { IFinancierSector } from '../interfaces'
import { INameIdPair } from '../../shared/interfaces'

type FinancierGeneralInformationProps = {
  handleClose: () => void;
  showModal: boolean;
  currentPage: number;
  pageSize: number;
};


const FinancierTypeMap: Record<string, string> = {
  grant: 'Grant Provider',
  finance: 'Financier',
}

const FinancierGeneralInformation: FC<FinancierGeneralInformationProps> = ({
  handleClose,
  showModal,
  currentPage,
  pageSize,
}) => {
  const { id } = useParams<{ id: string }>() as { id: string }
  useGetFinanciersQuery({ page: currentPage, pageSize })
  useGetFinancierCountriesQuery()

  const { data: financierSectorsState } = useGetFinancierSectorsQuery(id)
  const financierSectorsList: IFinancierSector[] = Object.values(financierSectorsState?.entities || {}) as IFinancierSector[]

  const { selectById: selectFinancierById } = createFinancierSelectors({
    page: currentPage,
    pageSize,
  })
  const { selectById: selectFinancierCountryById } = createFinancierCountriesSelectors()

  const financierDetails = useSelector((state: RootState) => selectFinancierById(state, id))

  const { countries, sectors }: { countries: INameIdPair[]; sectors: INameIdPair[] } = useSelector((state: RootState) => {
    const selectedCountries =
      financierDetails?.countries?.map((countryId) =>
        selectFinancierCountryById(state, countryId),
      ).filter((country): country is INameIdPair => country !== undefined) ?? []

    const selectedSectors =
      financierSectorsList.map((sector: IFinancierSector) =>
        sector ? selectSectorById(state, sector.id) : undefined,
      ).filter((sector): sector is INameIdPair => sector !== undefined) ?? []

    return { countries: selectedCountries, sectors: selectedSectors }
  })


  const financierTypeNames = financierDetails?.financierType
    ?.map((type) => FinancierTypeMap[type] || type)
    .join(', ')

  return (
    <div className="position-relative">
      <div className="card card-flush mb-4">
        <div className="card-body px-6 p-0">
          <div className="row">
            <div className="col-md-6 pt-5">
              <h3 className="fw-bolder text-dark fs-8 m-0">Name</h3>
              <p className="fs-7 text-justify">{financierDetails?.name || <LineSpan />}</p>
            </div>
            <div className="col-md-6 pt-5">
              <h3 className="fw-bolder text-dark fs-8 m-0">Investor Type</h3>
              <p className="fs-7 text-justify">{financierTypeNames || <LineSpan />}</p>
            </div>
          </div>

          <div className="row pb-2">
            <div className="col-md-12">
              <h3 className="fw-bolder text-dark fs-8 m-0">About</h3>
              <p className="fs-7 text-justify">{financierDetails?.about || <LineSpan />}</p>
            </div>
          </div>
          <div className="row">

            <div className="col-md-6">
              <h3 className="fw-bolder text-dark fs-8 m-0">Physical Address</h3>
              <p className="fs-7 text-justify">{financierDetails?.officeAddress || <LineSpan />}</p>
            </div>
            <div className="col-md-6">
              <h3 className="fw-bolder text-dark fs-8 m-0">Mail Address</h3>
              <p className="fs-7 text-justify">{financierDetails?.mailAddress || <LineSpan />}</p>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <h3 className="fw-bolder text-dark fs-8 m-0">Country</h3>
              <p className="fs-7 text-justify">{financierDetails?.country.join(', ') || <LineSpan />}</p>
            </div>
            <div className="col-md-6">
              <h3 className="fw-bolder text-dark fs-8 m-0">Region/Province</h3>
              <p className="fs-7 text-justify">{financierDetails?.region || <LineSpan />}</p>
            </div>
          </div>

          <div className="row pb-2">
            <div className="col-md-6">
              <h3 className="fw-bolder text-dark fs-8 m-0">Sectors of Interest</h3>
              <span className="fs-7 text-justify">
                {financierDetails?.sectors?.length ? financierDetails?.sectors.join(', ') : <LineSpan />}
              </span>
            </div>
            <div className="col-md-6">
              <h3 className="fw-bolder text-dark fs-8 m-0">Mode of Collaboration</h3>
              <span className="fs-7 text-justify">
                {financierDetails?.modeCollaboration?.length ? financierDetails?.modeCollaboration : <LineSpan />}
              </span>
            </div>
          </div>
          <div className="row pb-2">
            <div className="col-md-6">
              <h3 className="fw-bolder text-dark fs-8 m-0">Aligned with ESG Principles </h3>
              <span className="fs-7 text-justify">
                {financierDetails?.esgPrinciples?.length ? financierDetails?.esgPrinciples : <LineSpan />}
              </span>
            </div>
            <div className="col-md-6">
              <h3 className="fw-bolder text-dark fs-8 m-0">Open to co-investment</h3>
              <span className="fs-7 text-justify">
                {financierDetails?.coInvestment?.length ? financierDetails?.coInvestment : <LineSpan />}
              </span>
            </div>
          </div>
          <div className="row pb-2">
            <div className="col-md-6">
              <h3 className="fw-bolder text-dark fs-8 m-0">Require Additional Support</h3>
              <span className="fs-7 text-justify">
                {financierDetails?.additionalSupport?.length ? financierDetails?.additionalSupport : <LineSpan />}
              </span>
            </div>
          </div>
        </div>
      </div>
      <EditButton handleClose={handleClose} />
      <UpdateFinancierGeneralInformation
        showModal={showModal}
        handleClose={handleClose}
        currentPage={currentPage}
        pageSize={pageSize}
      />
    </div>
  )
}

export default FinancierGeneralInformation
