import { random, range } from "lodash";

export const printAmountInUSD = (number: number | undefined) => {

  if (!number) return ""

  try {
    return number ? new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(number) : "";
  } catch (e) {
    return "";
  }
}

export const printNumber = (number: number) => {
  try {
    return number ? new Intl.NumberFormat().format(number) : "";
  } catch (e) {
    return "";
  }
};

export const printDecimal = (number: number) => {
  try {
    return number
      ? new Intl.NumberFormat("en-US", { maximumFractionDigits: 2 }).format(
        number
      )
      : "";
  } catch (e) {
    return "";
  }
};

export const printInteger = (number: number) => {
  try {
    return number
      ? new Intl.NumberFormat("en-US", { maximumFractionDigits: 0 }).format(
        number
      )
      : "";
  } catch (e) {
    return "";
  }
};

export const randomInt = (min = 0, max = 10): number => {
  return random(min, max);
};

export const intRange = (min = 0, max = 10): number[] => {
  return range(min, max);
};

/**
 * Converts an integer to a percentage string.
 * @param {number} value - The integer value to convert.
 * @param {number} total - The total value for percentage calculation.
 * @returns {string} The percentage representation of the value.
 */
export const intToPercentage = (value: number, total: number = 6): number => {
  if (value === 0) {
    return 0;
  }
  const percentage = Math.ceil((value / total) * 100);
  return percentage;
};
export const formatBytes = (bytes: number): string => {
  if (bytes === 0) return "0 MB";
  const kbs = bytes / 1024;
  if (kbs < 1024) return `${Math.round(kbs)} KB`;
  const mbs = kbs / 1024;
  return `${mbs.toFixed(1)} MB`;
};

export const formatNumber = (value: string | number) => {
  if (!value) return ''; // Handle empty or null values
  const number = parseFloat(value.toString());
  if (isNaN(number)) return value.toString();
  return new Intl.NumberFormat().format(number);
}



