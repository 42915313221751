import {FC, useEffect, useRef, useState} from 'react'
import Flatpickr from 'react-flatpickr'
import 'flatpickr/dist/flatpickr.min.css'
import {Formik, Form, FormikValues, Field} from 'formik'
import {createServiceProviderSchema} from '../constants/CreateServiceProviderSchema'
import {ICreateServiceProvider} from '../interfaces'
import {Stepper} from '../../shared/components/stepper'
import {ServiceProviderCreateFormSteps} from '../../../../data/constants'
import {CreateBusinessOperations} from '../components'
import CreateServiceProviderGeneralInformation from '../components/CreateServiceProviderGeneralInformation'
import {CreateContactInformation} from '../../shared/components/CreateContactInformation'
import {useAddNewServiceProviderMutation} from '../ServiceProvidersApiSlice'
import {StepperComponent} from '../../shared/components/stepper/_StepperComponent'
import {Toolbar} from '../../../../_theme/layout/components/Toolbar'
import {useNavigate} from 'react-router'
import {ICreateDocument} from '../../documents'
import {useAddNewDocumentMutation} from '../../documents/DocumentsApiSlice'
import {useAuth} from 'react-oidc-context'
import {FetchBaseQueryError} from '@reduxjs/toolkit/query'

const CreateServiceProvider: FC = () => {
  const inits: ICreateServiceProvider = {
    generalInformation: {
      name: '',
      about: '',
      country: '',
      district: '',
      logo: '',
    },
    businessOperations: {
      yearOfIncorporation: '',
      sectors: [],
      serviceOffering: '',
    },
    contactPerson: {
      name: '',
      email: '',
      phoneNumber: '',
      address: '',
    },
  }
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const navigate = useNavigate()
  const [currentSchema, setCurrentSchema] = useState<any>()
  const [initValues] = useState<ICreateServiceProvider>(inits)
  const [submitting, setSubmitting] = useState(false)
  const flatpickrRef = useRef<Flatpickr>(null)
  const [countryCode, setCountryCode] = useState<string>('+256')
  const [addNewServiceProvider, {isLoading}] = useAddNewServiceProviderMutation()
  const [createDocument] = useAddNewDocumentMutation()
  const [fileObject, setFileObject] = useState<ICreateDocument | null>(null)
  const auth = useAuth()

  const stepName: any = (step: number) => {
    const steps = ['generalInformation', 'businessOperations', 'contactPerson', 'ReviewAndSubmit']
    return steps[step - 1]
  }

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)

    if (stepper.current) {
      const step = stepName(stepper.current.currentStepIndex)

      setCurrentSchema(createServiceProviderSchema.pick([step]))
    }
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    setSubmitting(false)

    const currentStepIndex = stepper.current.currentStepIndex

    if (currentStepIndex !== undefined && currentStepIndex > 0) {
      const step = stepName(currentStepIndex - 1)

      stepper.current.goPrev()

      setCurrentSchema(createServiceProviderSchema.pick([step]))
    }
  }

  const submitStep = async (values: ICreateServiceProvider, actions: FormikValues) => {
    if (!stepper.current) {
      return
    }
    const step = stepName(stepper.current.currentStepIndex + 1)

    setCurrentSchema(createServiceProviderSchema.pick([step]))

    if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      stepper.current.goNext()
    } else {
      setSubmitting(true)

      let logoId = null

      try {
        if (fileObject) {
          const documents = [fileObject]
          const payload = {files: documents}
          const response = await createDocument(payload).unwrap()
          logoId = response[0].id
          setFileObject(null)
        }
        await addNewServiceProvider({
          country: values.generalInformation.country,
          district: values.generalInformation.district,
          name: values.generalInformation.name,
          about: values.generalInformation.about,
          logo: logoId,
          dateOfIncorporation: values.businessOperations.yearOfIncorporation
            ? new Date(Number(values.businessOperations.yearOfIncorporation), 0, 1).toISOString()
            : null,
          serviceProviderSectors: values.businessOperations.sectors.map((sector) => ({
            sectorId: sector.id,
            sectorName: sector.name,
          })),
          serviceOffering: values.businessOperations.serviceOffering,
          contact: {
            name: values.contactPerson.name,
            telephone: values.contactPerson.phoneNumber
              ? `${countryCode}-${values.contactPerson.phoneNumber}`
              : '',
            email: values.contactPerson.email,
            address: values.contactPerson.address,
          },
          createdById: auth?.user?.profile?.sub,
          createdByName: `${auth?.user?.profile?.given_name} ${auth?.user?.profile?.family_name}`,
        }).unwrap()
        navigate('/services/providers')
      } catch (error) {
        const errorCode = (error as FetchBaseQueryError)?.status?.toString()
        navigate(`/error/${errorCode}`)
      }
    }
  }
  const formRef = useRef<any>()

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  return (
    <>
      <Toolbar page='Add a New Service Provider'></Toolbar>
      <div className='container-fluid p-0'>
        <div className='card'>
          <div
            ref={stepperRef}
            className='row stepper stepper-pills stepper-row'
            id='kt_modal_create_service_provider_stepper'
          >
            <Formik
              innerRef={formRef}
              validationSchema={currentSchema}
              initialValues={initValues}
              onSubmit={submitStep}
            >
              {({
                values,
                touched,
                errors,
                handleChange,
                setFieldValue,
                setFieldTouched,
                isValid,
                dirty,
              }) => (
                <Form className='form w-100' noValidate id='kt_modal_create_form'>
                  <div className='card-body d-flex flex-column px-lg-20 py-lg-15'>
                    <h2 className='f-s ps-xl-4 ps-xxl-7 ps-md-3'>Create a Service Provider</h2>
                    <div className='stepper-nav d-flex h-100px py-lg-20 px-xl-20 px-xxl-20'>
                      <Stepper items={ServiceProviderCreateFormSteps} />
                    </div>
                    <div className='flex-column-fluid py-lg-8 px-xl-4 px-md-4 px-xxl-8'>
                      {/* General Information */}
                      <CreateServiceProviderGeneralInformation
                        errors={errors}
                        touched={touched}
                        name='Business'
                        setFileObject={setFileObject}
                        setFieldValue={setFieldValue}
                      />

                      {/* Business Operations */}
                      <CreateBusinessOperations
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        errors={errors}
                        touched={touched}
                        flatpickrRef={flatpickrRef}
                        values={values}
                      />

                      {/* Contact Person */}
                      <CreateContactInformation
                        values={values}
                        errors={errors}
                        touched={touched}
                        setCountryCode={setCountryCode}
                      />

                      {/* Submission */}
                      <div data-kt-stepper-element='content'>
                        <div className='w-100 text-center'></div>
                      </div>
                    </div>
                    <div className='row mb-10 pt-0 px-lg-0 px-xl-0 px-md-0 px-xxl-0'>
                      <div className='col-lg-6'>
                        <button
                          type='button'
                          data-kt-stepper-action='previous'
                          onClick={prevStep}
                          className='btn btn-lg btn-outline btn-outline-secondary'
                          style={{
                            color:
                              stepper?.current?.currentStepIndex &&
                              stepper.current.currentStepIndex > 0
                                ? 'black'
                                : 'gray',
                          }}
                        >
                          Back
                        </button>
                      </div>
                      <div className='col-lg-6 text-md-end'>
                        <button
                          type='submit'
                          className={`btn btn-lg ${
                            stepper?.current?.currentStepIndex ===
                            stepper?.current?.totatStepsNumber!
                              ? 'btn-danger'
                              : 'btn-outline btn-outline-secondary'
                          }`}
                          style={{
                            color:
                              stepper?.current?.currentStepIndex ===
                              stepper?.current?.totatStepsNumber!
                                ? 'white'
                                : isValid && dirty
                                ? 'black'
                                : 'gray',
                          }}
                        >
                          <span className='indicator-label'>
                            {!submitting && (
                              <>
                                {stepper?.current?.currentStepIndex ===
                                stepper?.current?.totatStepsNumber!
                                  ? 'Submit'
                                  : 'Next'}
                              </>
                            )}
                            {submitting && (
                              <>
                                <span className='spinner-border h-15px w-15px align-middle text-white-400'></span>
                              </>
                            )}
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  )
}
export {CreateServiceProvider}
