import React, {FC} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {getInitials} from '../../../../utils/stringHelpers'
import {useSelector} from 'react-redux'
import {timeAgo} from '../../../../utils/dateHelpers'
import {createCompanySelectors, useGetCompanyDetailsQuery} from '../CompaniesApiSlice'
import {RootState} from '../../../../data/redux/Store'
import CardInitials from '../../shared/components/CardInitials'
import CardBadge from '../../shared/components/CardBadge'
import CardDetail from '../../shared/components/CardDetail'
import ProgressBar from '../../shared/components/ProgressBar'
import {INameIdPair} from '../../shared/interfaces'
import {IListPageFilters} from '../interfaces'
import {Col, Row} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import CustomSkeleton from '../../shared/components/CustomSkeleton'
import IdenticonAvatar from '../../shared/components/IdenticonAvatar'
import {useGetDocumentsQuery} from '../../documents/DocumentsApiSlice'

type CompanyCardProps = {
  id: string
  page: number
  pageSize: number
  searchTerm: string
  filters: IListPageFilters
  includeOnboardingStage?: boolean
  showCompanyCode?: boolean
  includeFinancingAsk?: boolean
  linkToCompanies?: boolean
  showCardIcons?: boolean
  isPipelineReady?: boolean
}

export const CompanyCard: FC<CompanyCardProps> = ({
                                                    id,
                                                    page,
                                                    pageSize,
                                                    searchTerm,
                                                    filters,
                                                    linkToCompanies = true,
                                                    includeOnboardingStage = true,
                                                    showCompanyCode = false,
                                                    includeFinancingAsk = false,
                                                    showCardIcons = false,
                                                    isPipelineReady = false,
                                                  }) => {
  const {selectById: selectCompanyById} = createCompanySelectors({
    page,
    pageSize,
    searchTerm,
    ...filters,
    ...(isPipelineReady ? {onboardingStatus: 'full'} : {}),
  })

  const company = useSelector((state: RootState) => selectCompanyById(state, id))
  const checklistValues = company?.onboardingStatus.split(',').map(Number)
  const completedSteps = checklistValues?.filter((value) => value === 1).length
  const companyInitials = getInitials(company?.name, 3)
  const intl = useIntl()
  const navigate = useNavigate()
  const {data: companyState, error} = useGetCompanyDetailsQuery(id)
  const companyDetails = companyState?.entities[id]

  const {data: documentsState, isLoading: gettingLogo} = useGetDocumentsQuery(
    companyDetails?.logo ? [companyDetails.logo] : [],
    {
      skip: !companyDetails?.logo,
    },
  )
  let logo
  if (companyDetails) {
    logo = documentsState?.entities[companyDetails.logo]
  }
  const handleNavigate = () => {
    navigate(`/companies/${company?.id}`, {
      state: {currentPage: page, pageSize},
    })
  }

  const countryString = company?.country ? company.country.split(',')[0] : '-'
  const regionString = company?.region ? company.region.split(',')[0] : '-'

  return (
    <div
      className="col-md-6 col-lg-4 col-sm-12 col-xl-4 min-height-200px pb-6">
      {!isPipelineReady ? (
        <Link to
                ={
          linkToCompanies
            ?
            `/companies/${company?.id}`
            :
            '#'}>
          <div
            className="card border border-2 border-gray-300 mb-1 h-100 d-flex flex-column justify-content-between"
            style={{cursor: 'pointer'}}
          >
            <div className="d-flex border-0 pt-2 px-5 justify-content-between align-items-center">
              {gettingLogo ? (
                <CustomSkeleton height={50} width={75} className="rounded" />
              ) : logo ? (
                <img
                  src={`data:${logo.fileType};base64,${logo.fileContent}`}
                  alt={logo.fileName}
                  height={50}
                  width={75}
                  style={{objectFit: 'contain'}}
                />
              ) : (
                <IdenticonAvatar identifier={companyInitials} size={50} />
              )}

              <div className="d-flex py-4 text-truncate" style={{
                maxWidth: '100%',
                display: 'block',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}>
                <CardBadge text={(company?.sectors?.[0]?.name ?? '').toUpperCase()} />
              </div>
            </div>
            <div className="px-5 py-2">
              <span
                className="fw-bolder text-dark fs-6 text-truncate"
                style={{
                  maxWidth: '100%',
                  display: 'block',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {showCompanyCode ? company?.code.toUpperCase() ?? '' : company?.name ?? ''}
              </span>
            </div>
            <Row className={`px-5 py-2`}>
              <Col>
                <CardDetail
                  IsInvestmentView={true}
                  title="Years of Operation"
                  showYearOfOperationIcon={showCardIcons}
                  value={company?.yearOfIncorporation ? timeAgo(company?.yearOfIncorporation) : '-'}
                />
              </Col>
            </Row>
            <Row className={`px-5 mb-2`}>
              <Col>
                <CardDetail
                  IsInvestmentView={true}
                  showLocationIcon={showCardIcons}
                  title="Location"
                  value={`${company?.country}, ${company?.region}`}
                />
              </Col>
            </Row>
            <Row className={`px-5 mb-2`}>
              <Col>
                <ProgressBar
                  value={completedSteps || 0}
                  total={6}
                  title="Onboarding Stage"
                  bold={true}
                />
              </Col>
            </Row>

            <Row className={`ps-7 ${includeFinancingAsk ? 'h-60px' : 'h-30px'}`}>
              {(company?.financingTypes ?? []).map((type: INameIdPair, index: number) => (
                <Col key={index} className="mb-2 flex-grow-0 px-1">
                  <CardBadge text={includeFinancingAsk ? `${type.name}` : type.name} />
                </Col>
              ))}
            </Row>
          </div>
        </Link>
      ) : (
        <div
          className="card border border-2 border-gray-300 mb-1 h-100 d-flex flex-column justify-content-between"
          style={{cursor: 'default'}}
        >
          <Row className="py-4 px-5">
            <Col>
             <span
               className="fw-bolder text-dark fs-6 text-truncate"
               style={{
                 maxWidth: '100%', display: 'block', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',
               }}
             >
                {showCompanyCode ? company?.code.toUpperCase() ?? '' : company?.name ?? ''}
              </span>
            </Col>
            <Col className="text-truncate text-end" style={{
              maxWidth: '100%',
              display: 'block',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}>
              <CardBadge text={(company?.sectors?.[0]?.name ?? '').toUpperCase()} />
            </Col>
          </Row>
          <Row className={`px-5 pb-3`}>
            <Col className="pe-2">
              <CardDetail
                title={intl.formatMessage({id: 'CARD.LABEL.YEARSOFOPERATION', defaultMessage: 'Years of Operation'})}
                showYearOfOperationIcon={showCardIcons}
                value={company?.yearOfIncorporation ? timeAgo(company?.yearOfIncorporation) : '-'}
              />
            </Col>
            <Col className="ps-2">
              <CardDetail
                showLocationIcon={showCardIcons}
                title={intl.formatMessage({id: 'CARD.LABEL.LOCATION', defaultMessage: 'Location'})}
                value={`${countryString}, ${regionString}`}
              />
            </Col>
          </Row>
          <Row className={`px-5 mb-3`}>
            <Col>
              <ProgressBar
                value={completedSteps || 0}
                total={6}
                title={intl.formatMessage({id: 'CARD.LABEL.ONBOARDINGSTAGE', defaultMessage: 'Onboarding Stage'})}
                bold={true}
              />
            </Col>
          </Row>

          <Row className={`ps-7 ${includeFinancingAsk ? 'h-40px' : 'h-40px'}`}>
            {(company?.financingTypes ?? []).map((type: INameIdPair, index: number) => (
              <Col key={index} className="mb-0 flex-grow-0 px-1">
                <CardBadge text={includeFinancingAsk ? `${type.name}` : type.name} />
              </Col>
            ))}
          </Row>
        </div>
      )}
    </div>
  )
}