import React, {FC, createContext, useContext, useState, useEffect} from 'react'

const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || 'i18nConfig'

type Language = 'fr' | 'en' | 'ar';

type Props = {
  selectedLang: Language
  updateLanguage: (newLang: Language) => void
}
const initialState: Props = {
  selectedLang: 'en',
  updateLanguage: () => {
  },
}

function getConfig(): Props {
  const ls = localStorage.getItem(I18N_CONFIG_KEY)
  if (ls) {
    try {
      return JSON.parse(ls) as Props
    } catch (er) {
      console.error(er)
    }
  }
  return initialState
}

// Side effect
export function setLanguage(lang: string) {
  localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({selectedLang: lang}))
}

const I18nContext = createContext<Props>(initialState)

const BlackI18nProvider: FC = ({children}) => {
  const [lang, setLang] = useState(getConfig().selectedLang)

  useEffect(() => {
    localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({selectedLang: lang}))
  }, [lang])

  const updateLanguage = (newLang: Language) => {
    setLang(newLang)
  }

  return <I18nContext.Provider value={{selectedLang: lang, updateLanguage}}>{children}</I18nContext.Provider>
}

const useLang = () => {
  return useContext(I18nContext).selectedLang
}

const useUpdateLang = () => {
  return useContext(I18nContext).updateLanguage
}

export {BlackI18nProvider, useUpdateLang, useLang, type Language}
