import { FC, useEffect, useRef, useState } from 'react'
import 'flatpickr/dist/flatpickr.min.css'
import { Formik, Form } from 'formik'
import { createFinancierSchema } from '../constants/CreateFinancierSchema'
import { Stepper, StepperComponent } from '../../shared/components/stepper'
import { ICreateFinancier } from '../interfaces'
import { CreateFinancierFormSteps } from '../../../../data/constants'
import { CreateContactInformation } from '../../shared/components/CreateContactInformation'
import {
  useAddNewFinancierMutation,
  useAddNewFinancierContactPersonMutation,
  useAddNewFinancierTypesMutation,
} from '../FinanciersApiSlice'
import { CreateFinancierFinancials, UpdateFinancierGeneralInformation } from '../modals'
import { Toolbar } from '../../../../_theme/layout/components/Toolbar'
import { useNavigate } from 'react-router'
import { useAddNewDocumentMutation } from '../../documents/DocumentsApiSlice'
import { ICreateDocument } from '../../documents'
import CreateFinancierGeneralInformation from '../components/CreateFinancierGeneralInformation'
import { useGetFinancingTypesQuery } from '../../companies/CompaniesApiSlice'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'
import LineSpan from '../../shared/components/LineSpan'
import { countries } from '../../../../utils/countries'
import { CreateFinancierOthers } from '../components/CreateFinancierOthers'
import { formatNumber } from '../../../../utils/numberHelpers'

const CreateFinancier: FC = () => {
  const inits: ICreateFinancier = {
    generalInformation: {
      name: '',
      about: '',
      country: [],
      region: '',
      district: '',
      logo: '',
      financierType: [],
      mailingAddress: '',
      officeAddress: '',
    },
    others: {
      impactGoals: '',
      additionalSupport: '',
      esgPrinciples: '',
      modeCollaboration: '',
      coInvestments: '',
    },
    businessOperations: {
      dateOfIncorporation: '',
      sector: '',
      subSector: '',
      productLine: '',
    },
    financials: {
      financingTypes: [],
      maximumDebt: '0',
      maximumEquity: '0',
      maximumGrant: '0',
      currency: 'UGX',
      ticketSize: '',
      sectors: [],
      investmentHorizon: [],
      maximumTicketSize: 0,
      minimumTicketSize: 0,
    },
    contactPersons: [{
      name: '',
      email: '',
      phoneNumber: '',
    }],
  }
  const navigate = useNavigate()
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [currentSchema, setCurrentSchema] = useState<any>()
  const [initValues] = useState<ICreateFinancier>(inits)
  const [submitting, setSubmitting] = useState(false)
  const [countryCode, setCountryCode] = useState<string>('+256')
  const [addNewFinancier, { isLoading }] = useAddNewFinancierMutation()
  const [addFinancierTypes] = useAddNewFinancierTypesMutation()
  const [createDocument] = useAddNewDocumentMutation()
  const [addNewFinancierContactPerson] = useAddNewFinancierContactPersonMutation()
  const [fileObject, setFileObject] = useState<ICreateDocument | null>(null)
  const { data: financingTypesState } = useGetFinancingTypesQuery()

  const financingTypeSelection = Object.values(financingTypesState?.entities || {})
  const stepName: any = (step: number) => {
    const steps = ['generalInformation', 'financials', 'contactPerson', 'others', 'ReviewAndSubmit']
    return steps[step - 1]
  }


  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)

    if (stepper.current) {
      const step = stepName(stepper.current.currentStepIndex)

      setCurrentSchema(createFinancierSchema.pick([step]))
    }
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    setSubmitting(false)

    const currentStepIndex = stepper.current.currentStepIndex
    if (currentStepIndex !== undefined && currentStepIndex > 0) {
      const step = stepName(currentStepIndex - 1)

      stepper.current.goPrev()

      setCurrentSchema(createFinancierSchema.pick([step]))
    }
  }

  const submitStep = async (values: ICreateFinancier) => {
    console.log(values)
    let financierTypesObjects: { financier_id: string; financing_type_id: string }[] = []
    if (!stepper.current) {
      return
    }
    const step = stepName(stepper.current.currentStepIndex + 1)

    setCurrentSchema(createFinancierSchema.pick([step]))

    if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      stepper.current.goNext()
    } else {
      setSubmitting(true)

      let logoId = null

      try {
        if (fileObject) {
          const documents = [fileObject]
          const payload = { files: documents }
          const response = await createDocument(payload).unwrap()
          logoId = response[0].id
          setFileObject(null)
        }

        const newFinancier = await addNewFinancier({
          name: values.generalInformation.name,
          about: values.generalInformation.about,
          country: values.generalInformation.country.map((country: any) => country.name),
          region: values.generalInformation.region,
          district: values.generalInformation.district,
          ticket_size: values.financials.ticketSize,
          logo: logoId,
          equity_maximum_ticket_size: parseFloat(
            values?.financials?.maximumEquity?.replace(/,/g, '') || '0',
          ),
          debt_maximum_ticket_size: parseFloat(
            values?.financials?.maximumDebt?.replace(/,/g, '') || '0',
          ),
          grant_maximum_ticket_size: parseFloat(
            values?.financials?.maximumGrant?.replace(/,/g, '') || '0',
          ),
          email: '',
          currency: values.financials.currency,
          sectors: values.financials.sectors.map((sector: any) => sector.name),
          maximum_ticket_size: values.financials.maximumTicketSize,
          minimum_ticket_size: values.financials.minimumTicketSize,
          physical_address: values.generalInformation.officeAddress,
          mail_address: values.generalInformation.mailingAddress,
          investment_horizon: values.financials.investmentHorizon,
          impact_goals: values.others.impactGoals,
          esg_principles: values.others.esgPrinciples,
          additional_support: values.others.additionalSupport,
          mode_collaboration: values.others.modeCollaboration,
          co_investment: values.others.coInvestments,
          financier_type: values?.generalInformation?.financierType.map((typeName: any) => typeName.name),
        }).unwrap()
        if (values.financials.financingTypes && values.financials.financingTypes.length > 0) {
          financierTypesObjects = values.financials.financingTypes.map((typeName) => {
            const type = financingTypeSelection.find((type) => type?.name === typeName)
            return {
              financier_id: newFinancier.id,
              financing_type_id: type ? type.id : '',
            }
          })
        }

        const financierTypePromises =
          financierTypesObjects.length > 0
            ? financierTypesObjects.map((financierType) =>
              addFinancierTypes(financierType).unwrap(),
            )
            : []

        const contactPersonsData = values.contactPersons.map(contact => ({
          full_name: contact.name,
          email: contact.email,
          phone_number: `${countryCode}-${contact.phoneNumber}`,
          financier: newFinancier.id,
        }))

        await Promise.all([
          ...financierTypePromises,
          addNewFinancierContactPerson(contactPersonsData),
        ])

        navigate('/financiers')
      } catch (error) {
        const errorCode = (error as FetchBaseQueryError)?.status?.toString()
        navigate(`/error/${errorCode}`)
      }
    }
  }
  const formRef = useRef<any>()

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  const financierType = [
    { id: 'finance', name: 'Financier' },
    { id: 'grant', name: 'Grant Provider' },
  ]

  const mappedCountries = countries.map((country) => ({
    id: country.value,
    name: country.label,
  }))

  return (
    <>
      <Toolbar page="Add a New Financier"></Toolbar>
      <div className="container-fluid p-0">
        <div className="card">
          <div
            ref={stepperRef}
            className="row stepper stepper-pills stepper-row"
            id="kt_modal_create_service_provider_stepper"
          >
            <Formik
              innerRef={formRef}
              validationSchema={currentSchema}
              initialValues={initValues}
              onSubmit={submitStep}
            >
              {({
                values,
                touched,
                errors,
                handleChange,
                setFieldValue,
                setFieldTouched,
                isValid,
                dirty,
              }) => (
                <Form className="form w-100" noValidate id="kt_modal_create_form">
                  <div className="card-body d-flex flex-column px-lg-20 py-lg-15">
                    <h2 className="f-s ps-xl-4 ps-xxl-7 ps-md-3">Create a Financier</h2>
                    <div className="stepper-nav d-flex h-100px py-lg-20 px-xl-20 px-xxl-20">
                      <Stepper items={CreateFinancierFormSteps} />
                    </div>
                    <div className="flex-column-fluid py-lg-8 px-xl-4 px-md-4 px-xxl-8">
                      {/* General Information */}

                      <CreateFinancierGeneralInformation
                        values={values}
                        errors={errors}
                        touched={touched}
                        setFileObject={setFileObject}
                        setFieldValue={setFieldValue}
                      />

                      {/*Business Operations */}

                      <CreateFinancierFinancials
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                      />

                      {/* contact person */}
                      <div data-kt-stepper-element="content" className="row ps-3">
                        <CreateContactInformation
                          values={values}
                          errors={errors}
                          touched={touched}
                          setCountryCode={setCountryCode}
                        />
                      </div>

                      {/* Others */}
                      <CreateFinancierOthers
                        values={values}
                        errors={errors}
                        touched={touched}
                        setCountryCode={setCountryCode}
                      />


                      {/* submission */}
                      <div data-kt-stepper-element="content">
                        <div className="fv-row row mb-10 p-0">
                          <div className="col-lg-6">
                            <h3 className="fw-bolder text-dark fs-8 m-0">Name</h3>
                            <p className="fs-7 text-justify">{values.generalInformation?.name ||
                              <LineSpan />}</p>
                          </div>
                          <div className="col-lg-6">
                            <h3 className="fw-bolder text-dark fs-8 m-0">About Financier</h3>
                            <p className="fs-7 text-justify">{values.generalInformation?.about ||
                              <LineSpan />}</p>
                          </div>

                          <div className="col-lg-6">
                            <h3 className="fw-bolder text-dark fs-8 m-0">Type of investor</h3>
                            <p className="fs-7 text-justify">
                              {(values?.generalInformation?.financierType.map((typeName: any) => typeName.name) ||
                                <LineSpan />)}
                            </p>
                          </div>

                          <div className="col-lg-6">
                            <h3 className="fw-bolder text-dark fs-8 m-0">Country</h3>
                            <p className="fs-7 text-justify">
                              {Array.isArray(values.generalInformation?.country) && values.generalInformation.country.length > 0
                                ? values.generalInformation.country.map((country: any) => country.name).join(', ')
                                : <LineSpan />}
                            </p>
                          </div>
                          <div className="col-lg-6 pt-5">
                            <h3 className="fw-bolder text-dark fs-8 m-0">Financing Type</h3>
                            <p className="fs-7 text-justify">{values.financials?.financingTypes.join(', ') ||
                              <LineSpan />}</p>
                          </div>

                          <div className="col-lg-6 pt-5">
                            <h3 className="fw-bolder text-dark fs-8 m-0">Investment Horizon</h3>
                            <p className="fs-7 text-justify">{values.financials?.investmentHorizon.join(', ') ||
                              <LineSpan />}</p>
                          </div>

                          <div className="col-lg-6 pt-5">
                            <h3 className="fw-bolder text-dark fs-8 m-0">Target Sectors</h3>
                            <p className="fs-7 text-justify">
                              {Array.isArray(values.financials?.sectors) && values.financials?.sectors.length > 0
                                ? values.financials?.sectors.map((country: any) => country.name).join(', ')
                                : <LineSpan />}
                            </p>
                          </div>

                          <div className="col-lg-6">
                            <h3 className="fw-bolder text-dark fs-8 m-0">Currency</h3>
                            <p className="fs-7 text-justify">
                              {values.financials.currency ||
                                <LineSpan />}</p>
                          </div>

                          <div className="col-lg-6">
                            <h3 className="fw-bolder text-dark fs-8 m-0">Ticket Size</h3>
                            <p className="fs-7 text-justify">
                              {values.financials?.minimumTicketSize && values.financials?.maximumTicketSize
                                ? `${formatNumber(values.financials.minimumTicketSize)} - ${formatNumber(values.financials.maximumTicketSize)}`
                                : <LineSpan />}
                            </p>
                          </div>

                          <div className="col-lg-6">
                            <h3 className="fw-bolder text-dark fs-8 m-0">Name of Contact Person 1</h3>
                            <p className="fs-7 text-justify">
                              {values.contactPersons[0]?.name ||
                                <LineSpan />}</p>
                          </div>

                          <div className="col-lg-6">
                            <h3 className="fw-bolder text-dark fs-8 m-0">Email Address</h3>
                            <p className="fs-7 text-justify">{values.contactPersons[0]?.email ||
                              <LineSpan />}</p>
                          </div>

                          <div className="col-lg-6">
                            <h3 className="fw-bolder text-dark fs-8 m-0">Phone Number</h3>
                            <p>
                              {values.contactPersons[0]?.phoneNumber || <LineSpan />}

                            </p>
                          </div>
                          <div className="col-lg-6">
                            <h3 className="fw-bolder text-dark fs-8 m-0">Impact Goals</h3>
                            <p>
                              {values.others?.impactGoals || <LineSpan />}

                            </p>
                          </div>
                          <div className="col-lg-6">
                            <h3 className="fw-bolder text-dark fs-8 m-0">Aligned to ESG principles </h3>
                            <p>
                              {values.others?.esgPrinciples || <LineSpan />}

                            </p>
                          </div>
                          <div className="col-lg-6">
                            <h3 className="fw-bolder text-dark fs-8 m-0">Open to co-investment</h3>
                            <p>
                              {values.others?.coInvestments || <LineSpan />}

                            </p>
                          </div>
                          <div className="col-lg-6">
                            <h3 className="fw-bolder text-dark fs-8 m-0">Prefered mode of collaboration</h3>
                            <p>
                              {values.others?.modeCollaboration || <LineSpan />}

                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-10 pt-0 px-xl-4 px-md-4 px-xxl-8">
                      <div className="col-lg-6">
                        <button
                          type="button"
                          data-kt-stepper-action="previous"
                          onClick={prevStep}
                          className="btn btn-lg btn-outline btn-outline-secondary"
                          style={{
                            color:
                              stepper?.current?.currentStepIndex &&
                                stepper.current.currentStepIndex > 0
                                ? 'black'
                                : 'gray',
                          }}
                        >
                          Back
                        </button>
                      </div>
                      <div className="col-lg-6 text-md-end">
                        <button
                          type="submit"
                          className={`btn btn-lg ${stepper?.current?.currentStepIndex ===
                            stepper?.current?.totatStepsNumber!
                            ? 'btn-danger'
                            : 'btn-outline btn-outline-secondary'
                            }`}
                          style={{
                            color:
                              stepper?.current?.currentStepIndex ===
                                stepper?.current?.totatStepsNumber!
                                ? 'white'
                                : isValid && dirty
                                  ? 'black'
                                  : 'gray',
                          }}
                        >
                          <span className="indicator-label">
                            {!submitting && (
                              <>
                                {stepper?.current?.currentStepIndex ===
                                  stepper?.current?.totatStepsNumber!
                                  ? 'Submit'
                                  : 'Next'}
                              </>
                            )}
                            {submitting && (
                              <>
                                <span className="spinner-border h-15px w-15px align-middle text-white-400"></span>
                              </>
                            )}
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  )
}
export { CreateFinancier }
